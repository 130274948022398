import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { NumberConverter } from "../helpers/numberConverter";
import opelAstra from "../Assets/img_car/detail/18.opel_astra.png";
import peugeot308 from "../Assets/img_car/detail/peugeot308.png";
import { useTranslation } from "react-i18next";
import { vehicleCodeChanger } from "../helpers/vehicleCodeChanger";
import PaymentSelection from "../Components/PaymentTypeSelection/PaymentSelection";

function Payment({
  vehicleChoosedDetails,
  reservationInfo,
  clientDetails,
  setPaymentAproved,
  vehicleExtras,
  totalRentalValue,
  paymentAproved,
  setPaypalOrderId,
  pickUpDesk,
  dropOffDesk,
  totalDays,
  youngDriverClient,
  setPaymentType,
  paymentType,
}) {
  const { t } = useTranslation();
  const [updatedTotalRentalValue, setUpdatedTotalRentalValue] =
    useState(totalRentalValue);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (youngDriverClient) {
      setUpdatedTotalRentalValue((totalRentalValue + 40).toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [youngDriverClient]);

  return (
    <div className="container">
      <div className="left-side">
        <PaymentSelection
          setPaypalOrderId={setPaypalOrderId}
          setPaymentAproved={setPaymentAproved}
          updatedTotalRentalValue={updatedTotalRentalValue}
          setUpdatedTotalRentalValue={setUpdatedTotalRentalValue}
          setPaymentType={setPaymentType}
          paymentType={paymentType}
        />
      </div>
      <div className="car-details">
        <div className="Payment">
          <>
            <div className="container">
              <div className="car-details">
                <Card>
                  <Typography
                    gutterBottom
                    sx={{ backgroundColor: "primary.main" }}
                    variant="h5"
                    align="center"
                    color="white"
                    component="div"
                  >
                    {t("myVehicle")}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    align="center"
                    color="primary.main"
                    component="div"
                  >
                    {t("group")}{" "}
                    {vehicleCodeChanger(vehicleChoosedDetails.vehicleCode)}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    align="center"
                    component="div"
                  >
                    {vehicleChoosedDetails.vehicleModel} {t("orSimilar")}
                  </Typography>
                  <CardMedia
                    sx={{ margin: "20px", height: 180, width: 300 }}
                    image={
                      vehicleChoosedDetails.vehicleCode === "TXT"
                        ? peugeot308
                        : vehicleChoosedDetails.pictureUrl
                        ? process.env.PUBLIC_URL +
                          "img_car/detail/" +
                          vehicleChoosedDetails.pictureUrl
                        : vehicleChoosedDetails.vehicleCode === "CDAV"
                        ? opelAstra
                        : vehicleChoosedDetails.vehicleCode === "CDAD"
                        ? peugeot308
                        : null
                    }
                    title="CAR"
                  />
                  <Typography
                    sx={{ backgroundColor: "primary.main" }}
                    gutterBottom
                    variant="h5"
                    align="center"
                    color="white"
                    component="div"
                  >
                    {t("myReservation")}
                  </Typography>
                  <>
                    <Typography
                      gutterBottom
                      variant="h6"
                      align="center"
                      component="div"
                    >
                      {t("pickUp")}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body1"
                      align="center"
                      component="div"
                    >
                      {pickUpDesk === 41
                        ? "Funchal- Rua Ivens Nº12"
                        : pickUpDesk === 42
                        ? "Aeroporto da Madeira"
                        : pickUpDesk === 47
                        ? "Calheta"
                        : pickUpDesk === 46
                        ? "Norte"
                        : "Unknown Location"}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body1"
                      align="center"
                      component="div"
                    >
                      {dayjs(reservationInfo?.date_pickup).format("DD/MM/YYYY")}{" "}
                      {t("at")} {reservationInfo?.time_pickup}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      align="center"
                      component="div"
                    >
                      {t("dropOff")}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body1"
                      align="center"
                      component="div"
                    >
                      {dropOffDesk === 41
                        ? "Funchal- Rua Ivens Nº12"
                        : dropOffDesk === 42
                        ? "Aeroporto da Madeira"
                        : dropOffDesk === 47
                        ? "Calheta"
                        : dropOffDesk === 46
                        ? "Norte"
                        : "Unknown Location"}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body1"
                      align="center"
                      component="div"
                    >
                      {dayjs(reservationInfo?.date_return).format("DD/MM/YYYY")}{" "}
                      {t("at")} {reservationInfo?.time_return}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      align="center"
                      component="div"
                    >
                      {t("carPerDayValue")}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body1"
                      align="center"
                      component="div"
                    >
                      <NumberConverter
                        number={vehicleChoosedDetails.totalPrice}
                      />
                      € {t("per")} {totalDays} {t("days")}
                    </Typography>
                    <Typography
                      sx={{ backgroundColor: "primary.main" }}
                      gutterBottom
                      variant="h5"
                      align="center"
                      color="white"
                      component="div"
                    >
                      {t("extrasChoosed")}
                    </Typography>
                    <TableContainer>
                      <Table sx={{ minWidth: 200 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("extra")}</TableCell>
                            <TableCell align="right">{t("cost")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {vehicleExtras?.extraFees
                            ?.filter(({ quantity }) => quantity > 0)
                            .map(
                              ({ extraName, quantity, totalValue }, index) => (
                                <TableRow
                                  key={`${extraName}-${index}`} // Use a combination of extraName and index for unique key
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {t(extraName)} X{quantity}
                                  </TableCell>
                                  <TableCell align="right">
                                    {totalValue}€
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {t("totalPrice")}
                            </TableCell>
                            <TableCell align="right">
                              {(Number(updatedTotalRentalValue) || 0).toFixed(
                                2
                              )}
                              €
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                </Card>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

Payment.propTypes = {
  vehicleChoosedDetails: PropTypes.any,
  reservationInfo: PropTypes.any,
  clientDetails: PropTypes.any,
  setPaymentAproved: PropTypes.any,
  vehicleExtras: PropTypes.any,
  totalRentalValue: PropTypes.any,
  paymentAproved: PropTypes.any,
  setPaypalOrderId: PropTypes.any,
  //bookingNumber:PropTypes.any,
  pickUpDesk: PropTypes.number,
  dropOffDesk: PropTypes.number,
  totalDays: PropTypes.number,
  youngDriverClient: PropTypes.bool,
  setPaymentType: PropTypes.any,
  paymentType: PropTypes.string,
};

export default Payment;
