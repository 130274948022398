import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  MenuItem,
  Select,
  Button,
  CircularProgress,
  Typography,
  Grid,
  Stack,
  Fab,
  CardContent,
  CardMedia,
  Card,
  IconButton,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
import background from "./Assets/background4.jpg";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { DESKS } from "./helpers/Constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ErrorModal from "./Components/Modal/ErrorModal";
import InformationModal from "./Components/Modal/InformationModal";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
import { vehicleCodeChanger } from "./helpers/vehicleCodeChanger";
import ClearIcon from "@mui/icons-material/Clear";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#EE8013",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

const Body = ({ choosedCar, setChoosedCar }) => {
  const [isLoading, setIsloading] = useState(false);
  const [getAvaiabilities, setAvaiabilities] = useState("");
  const [pickUpDesk, setPickUpDesk] = useState(41);
  const [dropOffDesk, setDropOffDesk] = useState(41);
  const [width, setWidth] = React.useState(window.innerWidth);
  const { t } = useTranslation();
  const [openErrorModal, setOpenErrorModal] = useState(false); // State to control modal visibility
  const [openInformationModal, setOpenInformationModal] = useState(false); // State to control modal visibility
  const validateDateReturn = (value, { parent }) => {
    if (!value || !parent.date_pickup) {
      return true;
    }
    return value >= parent.date_pickup;
  };

  const reservationTimeValidation = yup.object({
    loc_pickup: yup.number().required(t("mandatory")),
    date_pickup: yup
      .date()
      .required(t("mandatory"))
      .min(dayjs().add(2, "day"), t("pickUpDateValidation"))
      .typeError(t("validDate"))
      .test("date_pickup", t("datesCanTBeEqual"), function (value) {
        const { time_pickup, time_return, date_pickup, date_return } =
          this.parent;

        if (
          date_pickup.getTime() === date_return.getTime() &&
          time_pickup === time_return
        ) {
          return false;
        } else {
          return true;
        }
      }),
    time_pickup: yup
      .string()
      .required(t("mandatory"))
      .test("time_pickup", t("outsideBusinessHours"), (value) => {
        const hours = parseInt(value.split(":")[0]);
        const minutes = parseInt(value.split(":")[1]);

        return hours * 60 + minutes >= 510 && hours * 60 + minutes <= 1110;
      })
      .test("valid_time_pickup", t("timePickUpAfterReturn"), function (value) {
        const { time_pickup, time_return, date_pickup, date_return } =
          this.parent;

        if (!value || !time_pickup || !date_pickup || !date_return) {
          return true; // Skip this validation if any required value is missing
        }
        if (
          date_pickup.getTime() === date_return.getTime() &&
          time_pickup > time_return
        ) {
          return false;
        }

        return true; // If dates are different, no additional check needed
      }),
    loc_return: yup.number().required(t("mandatory")),
    date_return: yup
      .date()
      .required(t("mandatory"))
      .typeError(t("validDate"))
      .test("dateReturn", t("returnDateValidation"), validateDateReturn),
    time_return: yup
      .string()
      .required(t("mandatory"))
      .test("time_return", t("outsideBusinessHours"), (value) => {
        if (!value) return false;

        // Check if time is within business hours
        const hours = parseInt(value.split(":")[0], 10);
        const minutes = parseInt(value.split(":")[1], 10);
        const totalMinutes = hours * 60 + minutes;

        return totalMinutes >= 510 && totalMinutes <= 1110;
      }),
  });

  const {
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      loc_pickup: "41",
      date_pickup: dayjs().add(3, "day").format("YYYY-MM-DD"),
      time_pickup: dayjs(dayjs().hour(10).minute(0).second(0).toDate()).format(
        "HH:mm"
      ),
      loc_return: "41",
      date_return: dayjs().add(6, "day").format("YYYY-MM-DD"),
      time_return: dayjs(dayjs().hour(10).minute(0).second(0).toDate()).format(
        "HH:mm"
      ),
    },
    resolver: yupResolver(reservationTimeValidation),
    reValidateMode: "onChange",
  });

  const navigate = useNavigate();
  const handleChangePickUpDesk = (event) => {
    setValue("loc_pickup", event.target.value, { shouldValidate: true });
    setValue("loc_return", event.target.value, { shouldValidate: true });
    setPickUpDesk(event.target.value ?? getValues("loc_pickup"));
    setDropOffDesk(event.target.value ?? getValues("loc_return"));

    if (event.target.value === 47 || event.target.value === 46) {
      setOpenInformationModal(true);
    }
  };

  const handleChangeDropOffDesk = (event) => {
    setValue("loc_return", event.target.value, { shouldValidate: true });
    setDropOffDesk(event.target.value || getValues("loc_return"));
    if (event.target.value === 47 || event.target.value === 46) {
      setOpenInformationModal(true);
    }
  };

  const handleChangeTimePickup = (time) => {
    if (time?.$m === 0) {
      setValue("time_pickup", `${time?.$H}:${time?.$m}0`, {
        shouldValidate: true,
      });
    } else {
      setValue("time_pickup", `${time?.$H}:${time?.$m}`, {
        shouldValidate: true,
      });
    }
  };

  const handleChangeTimeReturn = (time) => {
    if (time?.$m === 0) {
      setValue("time_return", `${time?.$H}:${time?.$m}0`, {
        shouldValidate: true,
      });
    } else {
      setValue("time_return", `${time?.$H}:${time?.$m}`, {
        shouldValidate: true,
      });
    }
  };

  const handleChangeDatePickup = (date) => {
    setValue("date_pickup", dayjs(date?.$d).format("YYYY-MM-DD"), {
      shouldValidate: true,
    });
  };

  const handleChangeDateReturn = (date) => {
    setValue("date_return", dayjs(date?.$d).format("YYYY-MM-DD"), {
      shouldValidate: true,
    });
  };

  const [error, setError] = React.useState(null);

  const locations = [
    { id: 41, name: "Funchal" },
    { id: 42, name: "Airport" },
    { id: 47, name: "Calheta" },
    { id: 46, name: "Norte" },
  ];

  const fetchAvailabilities = async () => {
    const newAvaiabilities = [];

    for (const location of locations) {
      setValue("loc_pickup", location.id);
      setValue("loc_return", location.id);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify(getValues()),
      };

      try {
        const response = await fetch(
          "https://www.whynotcarrental.com/Backend/vehicleAvaibility.php",
          requestOptions
        );

        if (!response.ok) {
          // Log if the response is not OK (e.g., status 500)
          continue; // Skip this iteration
        }

        const vehicleAvaibility = await response.json();

        newAvaiabilities.push(
          vehicleAvaibility?.VehAvailRSCore?.VehVendorAvails?.VehVendorAvail
            ?.VehAvails
        );
      } catch (error) {
        // Log the error and continue with the next iteration
      }
    }

    const mergedAvaiabilities = [
      ...new Set([...getAvaiabilities, ...newAvaiabilities]),
    ];

    setAvaiabilities(mergedAvaiabilities);
    return mergedAvaiabilities; // Return the mergedAvaiabilities
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    await trigger();
    if (Object.keys(errors).length === 0 && isValid) {
      try {
        setIsloading(true);
        const avaiabilities = await fetchAvailabilities(); // Wait for fetchAvailabilities to complete

        setIsloading(false);
        navigate("/reservationCheckout", {
          state: {
            avaiabilities,
            reservationDetails: getValues(),
            pickUpDesk,
            dropOffDesk,
            width,
            choosedCar,
          },
        });
      } catch (error) {
        setIsloading(false);
        setError("Network error. Please try again later.");
      }
    } else {
      setOpenErrorModal(true);
      Object.entries(errors).forEach(([field, error]) => {});
    }
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleCloseInformationModal = () => {
    setOpenInformationModal(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <form onSubmit={(event) => handleSubmit(event)}>
          <ErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            errors={errors}
          />
          <InformationModal
            open={openInformationModal}
            handleClose={handleCloseInformationModal}
            message={t("hotelDeliveryInfo")}
          />
          <Box
            sx={{
              loading: "lazy",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#f9fafc",
                paddingTop: 3,
                paddingBottom: 7,
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 4,
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                maxWidth: "90%",
                width: {
                  xs: "100%",
                  sm: "100%", // 80% width on small screens
                  md: "90%", // 60% width on medium screens
                  lg: "90%", // 50% width on larger screens
                },
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={200} thickness={2} />
                </Box>
              ) : (
                <>
                  <Fab
                    color="primary"
                    aria-label="add"
                    sx={{
                      position: "fixed", // Keeps the button visible always
                      bottom: 16, // Adjust bottom distance from the viewport edge
                      right: 16, // Adjust right distance from the viewport edge
                    }}
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    } // Scrolls to top smoothly
                  >
                    <UpIcon />
                  </Fab>
                  {width > 1200 ? (
                    <>
                      <Typography
                        color={theme.palette.primary.main}
                        variant="h4"
                        component="div"
                      >
                        <span style={{ color: theme.palette.secondary.main }}>
                          Why Not
                        </span>{" "}
                        {t("title")}
                      </Typography>
                      <Grid
                        container
                        justifyContent="space-around"
                        sx={{
                          marginBottom: 2,
                          marginTop: 2,
                          flexDirection: { xs: "column", md: "row" },
                        }}
                      >
                        {/* Pick-up title */}
                        <Stack alignItems="center" direction="row" gap={1}>
                          <DirectionsCarIcon
                            sx={{
                              color: theme.palette.secondary.main,
                              fontSize: 30,
                            }}
                          />
                          <Typography
                            color={theme.palette.primary.main}
                            variant="h5"
                          >
                            {t("pickUp")}
                          </Typography>
                        </Stack>

                        {/* Drop-off title */}
                        <Stack alignItems="center" direction="row" gap={1}>
                          <LocationOnIcon
                            sx={{
                              color: theme.palette.secondary.main,
                              fontSize: 30,
                            }}
                          />
                          <Typography
                            color={theme.palette.primary.main}
                            variant="h5"
                          >
                            {t("dropOff")}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          flexWrap: { xs: "wrap", sm: "wrap", lg: "nowrap" }, // Wrap on small screens, no wrap on larger
                        }}
                      >
                        {/* Pick-Up Section */}
                        <Grid item sx={{ width: { lg: "100%", sm: "auto" } }}>
                          {" "}
                          {/* Full width on small screens */}
                          <FormControl
                            aria-label="loc_pickup"
                            sx={{ minWidth: { lg: "100%", xl: 230 } }}
                          >
                            {" "}
                            {/* Adjust minWidth for smaller screens */}
                            <Typography
                              color={theme.palette.primary.main}
                              variant="subtitle1"
                              gutterBottom
                              id="loc_pickup"
                            >
                              {t("location")}
                            </Typography>
                            <Select
                              aria-label="loc_pickup"
                              labelid="loc_pickup"
                              id="loc_pickup"
                              value={getValues("loc_pickup")}
                              name="loc_pickup"
                              label={t("deliveryDesk")}
                              onError={(newError) => setError(newError)}
                              onChange={handleChangePickUpDesk}
                            >
                              {DESKS.map(({ code, name }, index) => (
                                <MenuItem label={name} key={index} value={code}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        {/* Date Picker */}
                        <Grid item sx={{ width: { lg: "100%" } }}>
                          <Typography
                            color={theme.palette.primary.main}
                            variant="subtitle1"
                            gutterBottom
                          >
                            {t("date")}
                          </Typography>
                          <FormControl
                            aria-label="date_pickup"
                            sx={{ minWidth: { lg: "100%", xl: 150 } }}
                          >
                            <DatePicker
                              disablePast
                              name="date_pickup"
                              format="DD-MM-YYYY"
                              minDate={dayjs().add(3, "day")}
                              value={dayjs(getValues("date_pickup"))}
                              onChange={handleChangeDatePickup}
                              error={!!errors.date_pickup}
                              onError={(newError) => setError(newError)}
                            />
                          </FormControl>
                        </Grid>

                        {/* Time Picker */}
                        <Grid
                          item
                          sx={{
                            width: { lg: "100%" },
                            marginRight: { sm: 5 },
                          }}
                        >
                          <Typography
                            color={theme.palette.primary.main}
                            variant="subtitle1"
                            gutterBottom
                          >
                            {t("time")}
                          </Typography>
                          <FormControl
                            aria-label="time_pickup"
                            sx={{ minWidth: { lg: "100%", xl: 100 } }}
                          >
                            <TimePicker
                              value={dayjs(getValues("time_pickup"), "HH:mm")}
                              onChange={handleChangeTimePickup}
                              ampm={false}
                              error={!!errors.time_pickup}
                              onError={(newError) => setError(newError)}
                            />
                          </FormControl>
                        </Grid>

                        {/* Drop-Off Section */}
                        <Grid item sx={{ width: { lg: "100%" } }}>
                          <Typography
                            color={theme.palette.primary.main}
                            variant="subtitle1"
                            gutterBottom
                            id="loc_return"
                          >
                            {t("location")}
                          </Typography>
                          <FormControl
                            aria-label="loc_return"
                            sx={{ minWidth: { lg: "100%", xl: 250 } }}
                          >
                            <Select
                              aria-label="loc_return"
                              required
                              labelid="loc_return"
                              id="loc_return"
                              value={getValues("loc_return")}
                              name="loc_return"
                              label={t("returnDesk")}
                              onChange={handleChangeDropOffDesk}
                            >
                              {DESKS.map(({ code, name }, index) => (
                                <MenuItem
                                  aria-label={name}
                                  key={index}
                                  value={code}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        {/* Date Picker */}
                        <Grid item sx={{ width: { lg: "100%" } }}>
                          <Typography
                            color={theme.palette.primary.main}
                            variant="subtitle1"
                            gutterBottom
                          >
                            {t("date")}
                          </Typography>
                          <FormControl
                            aria-label="date_return"
                            sx={{ minWidth: { lg: "100%", xl: 150 } }}
                          >
                            <DatePicker
                              disablePast
                              name="date_return"
                              format="DD-MM-YYYY"
                              onError={(newError) => setError(newError)}
                              minDate={dayjs(getValues("date_pickup"))}
                              value={dayjs(getValues("date_return"))}
                              onChange={handleChangeDateReturn}
                              error={!!errors.date_return}
                            />
                          </FormControl>
                        </Grid>

                        {/* Time Picker */}
                        <Grid item sx={{ width: { lg: "100%" } }}>
                          <Typography
                            color={theme.palette.primary.main}
                            variant="subtitle1"
                            gutterBottom
                          >
                            {t("time")}
                          </Typography>
                          <FormControl
                            aria-label="time_return"
                            sx={{ minWidth: { lg: "100%", xl: 100 } }}
                          >
                            <TimePicker
                              value={dayjs(getValues("time_return"), "HH:mm")}
                              onChange={handleChangeTimeReturn}
                              ampm={false}
                              onError={(newError) => setError(newError)}
                              error={!!errors.time_return}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid container direction="column" spacing={2}>
                        {/* Pick-up Label */}
                        <Stack
                          alignItems="center"
                          direction="row"
                          gap={1}
                          sx={{ marginBottom: 1 }}
                        >
                          <DirectionsCarIcon
                            sx={{
                              color: theme.palette.secondary.main,
                              fontSize: 30,
                            }}
                          />
                          <Typography
                            color={theme.palette.primary.main}
                            variant="h5"
                            sx={{
                              "@media (max-width: 610px)": {
                                fontSize: "1.2rem", // Reduce font size on smaller screens
                              },
                            }}
                          >
                            {t("pickUp")}
                          </Typography>
                        </Stack>

                        {/* Pick-up Fields */}
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            flexWrap: "nowrap", // Default behavior
                            "@media (max-width: 610px)": {
                              flexWrap: "wrap", // Stack items vertically on small screens
                            },
                          }}
                        >
                          <Grid item>
                            <FormControl
                              aria-label="loc_pickup"
                              sx={{
                                minWidth: 250,
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                labelid="loc_pickup"
                              >
                                {t("location")}
                              </Typography>
                              <Select
                                aria-label="loc_pickup"
                                labelid="loc_pickup"
                                id="loc_pickup"
                                value={getValues("loc_pickup")}
                                name="loc_pickup"
                                label={t("deliveryDesk")}
                                onError={(newError) => setError(newError)}
                                onChange={handleChangePickUpDesk}
                              >
                                {DESKS.map(({ code, name }, index) => (
                                  <MenuItem
                                    aria-label={name}
                                    key={index}
                                    value={code}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item>
                            <Typography variant="subtitle1" gutterBottom>
                              {t("date")}
                            </Typography>
                            <FormControl
                              aria-label="date_pickup"
                              size="medium"
                              sx={{
                                "@media (max-width: 610px)": {
                                  minWidth: 250,
                                },
                              }}
                            >
                              <DatePicker
                                disablePast
                                name="date_pickup"
                                format="DD-MM-YYYY"
                                minDate={dayjs().add(3, "day")}
                                value={dayjs(getValues("date_pickup"))}
                                onChange={handleChangeDatePickup}
                                error={!!errors.date_pickup}
                                onError={(newError) => setError(newError)}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item>
                            <Typography variant="subtitle1" gutterBottom>
                              {t("time")}
                            </Typography>
                            <FormControl
                              aria-label="time_pickup"
                              size="medium"
                              sx={{
                                "@media (max-width: 610px)": {
                                  minWidth: 250,
                                },
                              }}
                            >
                              <TimePicker
                                value={dayjs(getValues("time_pickup"), "HH:mm")}
                                onChange={handleChangeTimePickup}
                                ampm={false}
                                error={!!errors.time_pickup}
                                onError={(newError) => setError(newError)}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Drop-off Section */}
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        sx={{ marginTop: 4 }}
                      >
                        {/* Drop-off Label */}
                        <Stack
                          alignItems="center"
                          direction="row"
                          gap={1}
                          sx={{ marginBottom: 1 }}
                        >
                          <LocationOnIcon
                            sx={{
                              color: theme.palette.secondary.main,
                              fontSize: 30,
                            }}
                          />
                          <Typography
                            color={theme.palette.primary.main}
                            variant="h5"
                            sx={{
                              "@media (max-width: 610px)": {
                                fontSize: "1.2rem", // Reduce font size on small screens
                              },
                            }}
                          >
                            {t("dropOff")}
                          </Typography>
                        </Stack>

                        {/* Drop-off Fields */}
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            flexWrap: "nowrap", // Default behavior
                            "@media (max-width: 610px)": {
                              flexWrap: "wrap", // Stack items vertically on small screens
                            },
                          }}
                        >
                          <Grid item>
                            <FormControl
                              aria-label="loc_return"
                              sx={{
                                minWidth: 250,
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                id="loc_return"
                              >
                                {t("location")}
                              </Typography>

                              <Select
                                aria-label="loc_return"
                                labelid="loc_return"
                                id="loc_return"
                                value={getValues("loc_return")}
                                name="loc_return"
                                label={t("returnDesk")}
                                onChange={handleChangeDropOffDesk}
                              >
                                {DESKS.map(({ code, name }, index) => (
                                  <MenuItem
                                    aria-label={name}
                                    key={index}
                                    value={code}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item>
                            <Typography variant="subtitle1" gutterBottom>
                              {t("date")}
                            </Typography>
                            <FormControl
                              aria-label="date_return"
                              size="medium"
                              sx={{
                                "@media (max-width: 610px)": {
                                  minWidth: 250,
                                },
                              }}
                            >
                              <DatePicker
                                disablePast
                                name="date_return"
                                format="DD-MM-YYYY"
                                onError={(newError) => setError(newError)}
                                minDate={dayjs(getValues("date_pickup"))}
                                value={dayjs(getValues("date_return"))}
                                onChange={handleChangeDateReturn}
                                error={!!errors.date_return}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item>
                            <Typography variant="subtitle1" gutterBottom>
                              {t("time")}
                            </Typography>
                            <FormControl
                              aria-label="time_return"
                              size="medium"
                              sx={{
                                "@media (max-width: 610px)": {
                                  minWidth: 250,
                                },
                              }}
                            >
                              <TimePicker
                                value={dayjs(getValues("time_return"), "HH:mm")}
                                onChange={handleChangeTimeReturn}
                                ampm={false}
                                onError={(newError) => setError(newError)}
                                error={!!errors.time_return}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {choosedCar?.brand ? (
                    <>
                      <Typography
                        color={theme.palette.primary.main}
                        variant="h5"
                        gutterBottom
                      >
                        {t("groupPreference")}
                      </Typography>
                      <Card sx={{ display: "flex" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: {
                              xs: "column",
                              sm: "row",
                              md: "row",
                              lg: "row",
                            },
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: {
                                xs: "column",
                                sm: "row",
                                md: "row",
                                lg: "row",
                              },
                            }}
                          >
                            <CardMedia
                              component="img"
                              sx={{ width: 151 }}
                              loading="lazy"
                              image={
                                process.env.PUBLIC_URL +
                                "img_car/detail/" +
                                choosedCar.picture
                              }
                              alt="Live from space album cover"
                            />
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <Typography component="div" variant="h5">
                                {choosedCar.brand}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{ color: "text.secondary" }}
                              >
                                {t("group")}{" "}
                                {vehicleCodeChanger(choosedCar.code)}
                              </Typography>
                            </CardContent>
                          </Box>
                          <IconButton
                            aria-label="clear"
                            onClick={() => setChoosedCar(null)}
                          >
                            <ClearIcon sx={{ fontSize: "2.5rem" }} />
                          </IconButton>
                        </Box>
                      </Card>
                    </>
                  ) : null}

                  {/* Row for Checkbox and Search Button */}
                  <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    sx={{ marginTop: 2 }}
                  >
                    <Grid
                      item
                      sx={{
                        textAlign: { lg: "center", xl: "right" },
                        width: "100%",
                      }}
                    >
                      <Button
                        type="submit"
                        value="Send"
                        variant="contained"
                        endIcon={<SendIcon />}
                        size="large"
                        disabled={isLoading}
                      >
                        <input
                          type="hidden"
                          name="action"
                          value="GetVehAvailRate"
                        ></input>
                        {t("search")}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Box>
        </form>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default Body;
