import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import html2pdf from "html2pdf.js";
import { Typography } from "@mui/material";
import { vehicleCodeChanger } from "../helpers/vehicleCodeChanger";
import Logo from "../Assets/logo.png";
const Confirmation = ({
  vehicleChoosedDetails,
  reservationDetails,
  checkInData,
  clientDetails,
  vehicleExtras,
  totalRentalValue,
  pickUpDesk,
  dropOffDesk,
  totalDays,
  paymentType,
}) => {
  const { t } = useTranslation();
  const hasRun = useRef(false); // Initialize ref to track execution

  useEffect(() => {
    if (hasRun.current) return; // Skip if the effect has already run

    hasRun.current = true; // Mark as executed

    const element = document.querySelector("#confirmation");
    var opt = {
      margin: 20,
      filename: "whyNotCarRentalBookingConfirmation.pdf",
    };

    html2pdf(element, opt);

    let emailExtras = "";

    vehicleExtras?.extraFees
      ?.filter((fee) => fee.quantity > 0)
      ?.map(
        (fee) => (emailExtras += `\n${fee.extraName} -> ${fee.totalValue}€ \n`)
      );

    const emailData = {
      idreserva: checkInData.idreserva,
      totalRentalValue: totalRentalValue,
      fullName: clientDetails.customer_name,
      email: clientDetails.customer_email,
      model: vehicleChoosedDetails.vehicleModel,
      group: vehicleChoosedDetails.vehicleCode,
      pickupPlace:
        pickUpDesk === 41
          ? "Funchal- Rua Ivens Nº12"
          : pickUpDesk === 42
          ? "Aeroporto da Madeira"
          : pickUpDesk === 47
          ? "Calheta"
          : pickUpDesk === 46
          ? "Norte"
          : "Unknown Location",
      returnPlace:
        pickUpDesk === 41
          ? "Funchal- Rua Ivens Nº12"
          : pickUpDesk === 42
          ? "Aeroporto da Madeira"
          : pickUpDesk === 47
          ? "Calheta"
          : pickUpDesk === 46
          ? "Norte"
          : "Unknown Location",
      pickupDate: reservationDetails.date_pickup,
      pickupTime: reservationDetails.time_pickup,
      returnDate: reservationDetails.date_return,
      returnTime: reservationDetails.time_return,
      totalDays: totalDays,
      extras: emailExtras,
      phoneNumber: clientDetails.customer_telephone,
      countryCode: clientDetails.countryCode,
    };

    emailjs.send(
      "service_e7useue",
      "template_c722lsd",
      emailData,
      "05_Hr3dTwXkn7tQ9c"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="confirmation">
      <>
        <Typography variant="h4">{t("bookingConfirmed")}</Typography>
        <Typography variant="h5">{t("bookingSummary")}</Typography>
        <div>
          <img
            alt="logo"
            className="h-18"
            width={200}
            height={100}
            src={Logo}
          />
        </div>
        <Typography variant="body1">
          <b>{t("orderId")}</b>: {checkInData.idreserva}
        </Typography>
        <Typography variant="body1">
          {t("pickUp")}:{" "}
          {pickUpDesk === 41
            ? "Funchal- Rua Ivens Nº12"
            : pickUpDesk === 42
            ? "Aeroporto da Madeira"
            : pickUpDesk === 47
            ? " Calheta"
            : pickUpDesk === 46
            ? " Norte"
            : "Unknown Location"}
        </Typography>
        <Typography>
          {t("deliveryDate")}: {reservationDetails.date_pickup}
        </Typography>
        <Typography>
          {t("deliveryTime")}: {reservationDetails.time_pickup}
        </Typography>
        <Typography>
          {t("dropOff")}:{" "}
          {dropOffDesk === 41
            ? " Funchal- Rua Ivens Nº12"
            : dropOffDesk === 42
            ? " Aeroporto da Madeira"
            : dropOffDesk === 47
            ? " Calheta"
            : dropOffDesk === 46
            ? " Norte"
            : "Unknown Location"}
        </Typography>
        <Typography>
          {t("returnDate")}: {reservationDetails.date_return}
        </Typography>
        <Typography>
          {t("returnTime")}: {reservationDetails.time_return}
        </Typography>
        <Typography variant="body1">
          <b>{t("clientDetails")}:</b>
        </Typography>
        <Typography>
          {t("fullName")}: {clientDetails.customer_name}
        </Typography>
        <Typography>
          {t("email")}: {clientDetails.customer_email}
        </Typography>
        <Typography>
          {t("phone")}: {clientDetails.countryCode}-
          {clientDetails.customer_telephone}
        </Typography>
        <Typography>{t("vehicleChoosed")}:</Typography>
        <Typography>
          {t("model")}: {vehicleChoosedDetails.vehicleModel}
        </Typography>
        <Typography>
          {t("group")}: {vehicleCodeChanger(vehicleChoosedDetails.vehicleCode)}
        </Typography>
        <Typography>{t("extrasChoosed")}:</Typography>
        {vehicleExtras?.extraFees
          ?.filter((fee) => fee.quantity > 0)
          ?.map((fee) => (
            <Typography>{fee.extraName}</Typography>
          ))}
        <Typography>
          <b>{t("furtherQuestions")} </b>
        </Typography>
        <Typography href={"mailto:front.office@whynotcarrental.com"}>
          <b>{t("email")}:</b> reservations@whynotcarrental.com
        </Typography>
        <Typography target="_blank" href="tel:00351291213100">
          <b>{t("phone")}:</b> +351 291213100
        </Typography>
        <Typography>{t("bestRegards")}</Typography>
        <Typography>WhyNot Car Rental</Typography>
      </>
    </div>
  );
};

Confirmation.propTypes = {
  vehicleChoosedDetails: PropTypes.any,
  reservationDetails: PropTypes.any,
  checkInData: PropTypes.any,
  clientDetails: PropTypes.any,
  vehicleExtras: PropTypes.any,
  totalRentalValue: PropTypes.any,
  pickUpDesk: PropTypes.number,
  dropOffDesk: PropTypes.number,
  totalDays: PropTypes.number,
  paymentType: PropTypes.string,
};

export default Confirmation;
